<template>
  <div class="q-pa-sm">
    <!-- 즉시조치 -->
    <c-table
      title="즉시조치"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="moc.imprImmData"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editable&&!disabled"
      @linkClick="immlinkClick"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!disabled" label="추가" icon="add" @btnClicked="addImprImm" />
        </q-btn-group>
      </template>
    </c-table>
    <!-- 개선요청 -->
    <c-table
      class="q-mt-md"
      title="개선요청"
      :columns="grid2.columns"
      :gridHeight="grid2.height"
      :data="moc.imprData"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editable&&!disabled"
      @linkClick="imprlinkClick"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!disabled" label="추가" icon="add" @btnClicked="addImpr" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'moc-impr-tab-detail',
  props: {
    moc: {
      type: Object,
      default: () => ({
        sopMocId: '',  // MOC 일련번호
        mocTypeCd: 'MT00000001',  // 변경구분_공통코드(정상/비상/임시)
        mocStepCd: '',  // 변경관리 단계
        mocTitle: '',  // 변경관리 제목
        prestartupImproves: [],
        improves: [],
        imprImmData: [],
        imprData: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
  },
  data() {
    return {
      editable: true,
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            // 사업장
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            // 조치부서
            label: '조치부서',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'link',
          },
          {
            name: 'actionContents',
            field: 'actionContents',
            // 조치내용
            label: '조치내용',
            align: 'left',
            style: 'width:400px',
            sortable: false,
          },
          {
            name: 'actionCompleteDate',
            field: 'actionCompleteDate',
            // 조치완료일
            label: '조치완료일',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
        ],
        height: '350px'
      },
      grid2: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            // 조치부서
            label: '조치부서',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            // 제목
            label: '제목',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'link',
          },
          {
            name: 'improveRequestContents',
            field: 'improveRequestContents',
            // 요청내용
            label: '요청내용',
            align: 'left',
            style: 'width:400px',
            sortable: false,
          },
          {
            name: 'actionCompleteRequestDate',
            field: 'actionCompleteRequestDate',
            // 조치완료 요청일
            label: '조치완료 요청일',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
        ],
        height: '362px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
    },
    addImprImm() {
      this.popupOptions.title = '즉시개선'; // 즉시개선
      this.popupOptions.param = {
        requestContents: '[변경관리] ' + this.moc.mocTitle,
        relationTableKey: this.moc.sopMocId,
        ibmTaskTypeCd: 'ITT0000025',
        ibmTaskUnderTypeCd: 'ITTU000040',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    addImpr() {
      this.popupOptions.title = '개선요청';
      this.popupOptions.param = {
        requestContents: '[변경관리] ' + this.moc.mocTitle,
        relationTableKey: this.moc.sopMocId,
        ibmTaskTypeCd: 'ITT0000025',
        ibmTaskUnderTypeCd: 'ITTU000040',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      this.$emit('saveCallback', this.moc.sopMocId)
    },
    /* eslint-disable no-unused-vars */
    immlinkClick(row, col, index) {
      this.imprData = row;
      this.popupOptions.title = '즉시개선 상세'; // 즉시개선 상세
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      this.popupOptions.param = {
        sopImprovementId: row.sopImprovementId,
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImmDetailPopup;
    },
    closeImmDetailPopup(result, flag) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        if (flag !== 'delete') {
          this.$_.extend(this.imprData, result.col1)
        } else {
          this.moc.imprImmData = this.$_.reject(this.moc.imprImmData, { sopImprovementId: result.col1.sopImprovementId })
        }
      }
    },
    imprlinkClick(row, col, index) {
      this.imprData = row;
      this.popupOptions.title = '개선';
      this.popupOptions.param = {
        sopImprovementId: row.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprDetailPopup;
    },
    closeImprDetailPopup(result, flag) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        if (flag !== 'delete') {
          this.$_.extend(this.imprData, result.col1)
        } else {
          this.moc.imprData = this.$_.reject(this.moc.imprData, { sopImprovementId: result.col1.sopImprovementId })
        }
      }
    },
  }
};
</script>
